<template>
  <v-row no-gutters justify="center">

    <v-col cols="12" sm="10" md="9">
      <extract/>
    </v-col>
  </v-row>
</template>

<script>

export default {
  components: {
    extract: () => import("@/site/modules/server/extract/extract"),
  },
  data: () => ({
    // server_data: null,
  }),
}
</script>
